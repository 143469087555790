import * as React from "react"
import { Layout, _Section } from "../components/shared"
import { _processLink } from "../components/shared/links.styles"
import { PageWrapper } from "../utils/PageWrapper"
import { graphql } from "gatsby"
import { _h1 } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { prismicGetText, prismicGetRichText, extractPrismicNode } from "../utils/prismicHelper"
import { Hero } from "../components/productsPage/shared"
import { ReactTypeformEmbed } from "react-typeform-embed"
import styled from "styled-components"

export const query = graphql`
    query BriefQuery($uid: String) {
        prismic {
            ...briefTemplateFragment
        }
    }
`

interface BriefPageProps {
    data: {
        prismic: {
            allBriefs: {
                edges: any
            }
        }
    }
}

const _typeFormContainer = styled.div`
    height: 600px;
    position: relative;
`

interface RemoteFromProps {
    url: string
}

const TypeFromSection: React.FC<RemoteFromProps> = ({ url }) => (
    <_Section>
        <_typeFormContainer>
            <ReactTypeformEmbed popup={false} url={url} />
        </_typeFormContainer>
    </_Section>
)

const AirtableFormSection: React.FC<RemoteFromProps> = ({ url }) => {
    const urlSplitArray = url.split("/")
    const formId = urlSplitArray[urlSplitArray.length - 1]

    const embededFrom = `<iframe class="airtable-embed" src="https://airtable.com/embed/${formId}?backgroundColor=orange" frameborder="0" onmousewheel="" width="100%" height="533" style="background: transparent; border: 1px solid #ccc;"></iframe>`
    return (
        <_Section>
            <div dangerouslySetInnerHTML={{ __html: embededFrom }} />
            <_typeFormContainer></_typeFormContainer>
        </_Section>
    )
}

const BriefPage: React.FC<BriefPageProps> = ({ data }) => {
    const [isReady, setIsReady] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            setIsReady(true)
        }
    })

    const doc = extractPrismicNode(data, "allBriefs")
    if (!doc) return null
    if (!isReady) return null

    return (
        <>
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero
                            description="Brief"
                            title={prismicGetText(doc.title_brief)}
                            lead={prismicGetRichText(doc.description_brief)}
                            leftSideLgWidth={12}
                        />
                    </_Section>

                    {!doc.code_from_airtable && doc.code_from_typeform && doc.code_from_typeform.embed_url && (
                        <TypeFromSection url={doc.code_from_typeform.embed_url} />
                    )}

                    {doc.code_from_airtable && doc.code_from_airtable.embed_url && (
                        <AirtableFormSection url={doc.code_from_airtable.embed_url} />
                    )}
                </Layout>
            </PageWrapper>
        </>
    )
}

export default BriefPage
